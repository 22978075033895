var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_vm._m(0), _c('div', {
    staticClass: "block-content"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.accounts,
      "total": _vm.totalAccounts,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "fetch-items": _vm.refreshTable
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function (slotProps) {
        return [_c('router-link', {
          staticClass: "font-w600",
          attrs: {
            "to": {
              name: 'account-view',
              params: {
                id: slotProps.data._id
              }
            }
          }
        }, [_vm._v(_vm._s(slotProps.data.name))])];
      }
    }, {
      key: "countryCode",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(slotProps.data.registeredAddress.countryCode.toUpperCase()) + " ")];
      }
    }, {
      key: "ocUpdatedDate",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm._f("date")(slotProps.data.ocUpdatedDate, 'ddd D MMM YYYY HH:mm:ss')) + " ")];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-header block-header-default"
  }, [_c('h3', {
    staticClass: "block-title"
  }, [_vm._v("All Accounts")])]);

}]

export { render, staticRenderFns }